import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Button } from "react-bootstrap"
import { Link } from "gatsby"

const fillerimg = require("../images/hybridprogramming.png")
const gateway = require("../images/gateway.png")
const pcslogo = require("../images/pcslogo.png")

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="SCAIGATE" description="" lang="en" meta={[]} />

      {/* Hero */}
      <div className="banner-image scaigate">
        <div className="hero-text">
          <h1>SCAIGATE</h1>
          <h3>An open platform for science.</h3>
          <div className="btn-div">
            <Button variant="light" to="/" as={Link}>
              Get Started
            </Button>
            <Button variant="light" to="/pcs" as={Link}>
              Go to PCS
            </Button>
          </div>
        </div>

        {/* Principles Section */}
        <section className="info-section">
          <div className="container">
            <div className="row">
              <div className="col-md-4 ">
                <div className="info-block">
                  <h3>Reliable Data Lakes</h3>
                  <p>
                    Delta Lake brings data reliability and scalability to your
                    existing data lake, with an open source storage layer
                    designed for the full data lifecycle.
                  </p>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="info-block">
                  <h3>Fast And Efficient Data Pipelines</h3>
                  <p>
                    Simple data processing on auto-scaling infrastructure.
                    Powered by highly optimized Apache Spark™ for up to 50x
                    performance gains.
                  </p>
                </div>
              </div>
              <div className="col-md-4 ">
                <div className="info-block">
                  <h3>Lightning-fast performance</h3>
                  <p>
                    With Apache Spark™ under the hood, Delta Lake delivers
                    massive scale and speed-- due in part to its optimized
                    performance features like indexing.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* About Section */}
      <section className="bg-darkened">
        <div className="container">
          <div className="row">
            <h2 className="text-center mb-5">A Science Gateway for All.</h2>

            <p className="mb-5">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
              tempora laborum at nesciunt, doloribus dolor. Eveniet vel nihil
              asperiores ratione quia odit consequatur eos culpa ipsa doloribus
              esse ad aliquam optio consectetur, est assumenda facere beatae
              architecto! Fugit rerum accusantium dolorum officiis esse quia
              exercitationem praesentium.
            </p>

            <img className="img-fluid" src={gateway} />
          </div>
        </div>
      </section>

      {/* Built By Scientists Section */}
      <section>
        <div className="container">
          <div className="row mb-5">
            <h2 className="text-center mb-5">
              Built by Scientists for Scientists.
            </h2>

            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Suscipit
              tempora laborum at nesciunt, doloribus dolor. Eveniet vel nihil
              asperiores ratione quia odit consequatur eos culpa ipsa doloribus
              esse ad aliquam optio consectetur, est assumenda facere beatae
              architecto! Fugit rerum accusantium dolorum officiis esse quia
              exercitationem praesentium.
            </p>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <h3>Scientific Machine Learning</h3>
              <p>New approach to large-scale Science Applications</p>
              <img className="img-fluid" src={fillerimg} />
            </div>
            <div className="col-lg-4">
              <h3>Heterogenous Computing</h3>
              <p>Backed by Reconfigurable Architectures</p>
              <img className="img-fluid" src={fillerimg} />
            </div>
            <div className="col-lg-4">
              <h3>Education &amp; Training</h3>
              <p>Promote collaboration &amp; workforce development</p>
              <img className="img-fluid" src={fillerimg} />
            </div>
          </div>
        </div>
      </section>

      {/* Featured Section */}
      <section className="bg-darkened">
        <div className="container">
          <div className="row">
            <h2 className="text-center mb-5">Featured Application.</h2>

            <div className="d-flex justify-content-center mb-3">
              <Link to="/pcs">
                <img src={pcslogo} className="mx-auto" height={60} width={60} />
              </Link>
            </div>
            <Link to="/pcs">
              <h3 className="text-center glow-hover">
                Productive computational science platform
              </h3>
            </Link>
            <p className="text-center">
              User-friendly programming environment for next-generation
              scientific computing
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage
